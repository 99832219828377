import React from "react";
import { MAIN_URL } from "../constant/index";
import { Body } from "./Body";

export const Main = () => {
  window.location = MAIN_URL;
  return (
    <Body>
      <h1>DoctorApp</h1>
    </Body>
  );
};
