import React from "react";
import { Body } from "./Body";
import { useState } from "react";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { API_URL, MAIN_URL } from "../constant";

export const ResetPassword = () => {
  const [password, setPassword] = useState("");
  let { id } = useParams();

  const handleChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const handleClick = async () => {
    if (password.length < 1) return;

    try {
      const response = await fetch(API_URL + "/account/password/reset/" + id, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ password }),
      });
      const data = await response.json();

      if (data.code === 200) {
        Swal.fire({
          icon: "success",
          title: "Contraseña cambiada",
          showConfirmButton: false,
          timer: 20000,
        });
        window.location = MAIN_URL;
      } else {
        Swal.fire({
          icon: "error",
          title: data.messageError
            ? data.messageError
            : "Este Link ya fue utilizado.",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error.message,
      });
    }
  };
  return (
    <Body>
      <h1>DoctorApp</h1>
      <form onSubmit={handleSubmit}>
        <div className="txt_field">
          <input
            type="password"
            required
            value={password}
            onChange={handleChange}
          />
          <span></span>
          <label>Nueva Contraseña</label>
        </div>
        <button onClick={handleClick}>Cambiar contraseña</button>
      </form>
    </Body>
  );
};
